.audio-info-panel-horizontal {
  width: 99vw;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-top: 5px;
  background-color: #ffffff;
  z-index: 1000;
}

.audio-info-panel-horizontal img {
  border-radius: 12px;
}

.audio-info-text-container {
  min-width: 500px;
  text-align: left;
}

.audio-detail-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.audio-detail-info span {
  font-family: "Helvetica";
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
  width: 128px;
}

.progress-bar {
  width: 100%;
  height: 5px;
}

.progress-bar__background {
  width: 100%;
  height: 5px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.progress-bar__completed {
  height: 5px;
  background-color: #8350f2;
  position: relative;
  border-radius: 5px 0 0 5px;
}

.progress-bar__highlight {
  position: absolute;
  right: -2px;
  top: 0;
  width: 4px;
  height: 5px;
  background: rgba(255, 255, 255, 0.7); /* 白色光点 */
  border-radius: 50%;
}