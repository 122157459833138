.user-info {
  padding: 10px;
}

.user-info-name__guest {
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.user-info-name__active {
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 13px;
  font-weight: 700;
}

.user-info-balance__title {
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 12px;
  font-weight: 300;
}

.user-info-balance__value {
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 13px;
  font-weight: 300;
  color: orange;
}