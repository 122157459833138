.auth-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://echo-us-west.oss-us-west-1.aliyuncs.com/public/img/auth-background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

.auth-title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}