.audio-card {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
}

.audio-card img {
  border-radius: 8px;
  flex-shrink: 0;
}

.audio-text-info {
  width: 100%;
  flex-grow: 1;
  min-width: 0;
}

.audio-text-info__title {
  font-family: "Helvetica";
  font-weight: bold;
  font-size: 12px;
  padding-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.audio-text-info__detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
  width: 100%;
  flex-grow: 1;
  min-width: 0;
}

.audio-text-info__detail-text {
  font-family: "Helvetica";
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
  flex-shrink: 0;
}

.audio-text-info__detail-text-shrink {
  font-family: "Helvetica";
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.audio-card-dropdown {
  align-self: flex-start;
}
