.dict-card {
  margin-bottom: 12px;
}

.dict-card__bold {
  font-size: 16px;
  font-weight: bold;
}

.dict-card-title span {
  margin-right: 12px;
  display: inline-block;
  white-space: normal;
}

.dict-entry-container span {
  margin-right: 12px;
  display: inline-block;
  white-space: normal;
}